var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2","xs":"2"}},[_c('h3',{staticClass:"ml-3",staticStyle:{"color":"#e33354"}},[_vm._v(" "+_vm._s(_vm.$t("MENU.contact_form"))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2","xs":"2"}},[_c('v-select',{attrs:{"items":_vm.contactFormTypeCollection,"label":_vm.$t('FORM_INPUT_NAMES.type'),"item-value":"id","item-text":"name","dense":""},model:{value:(_vm.contactFormType),callback:function ($$v) {_vm.contactFormType=$$v},expression:"contactFormType"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"3","xs":"4"}},[(_vm.contactFormListCollection.length != 0)?_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateRange=$event},"update:return-value":function($event){_vm.dateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pb- pt-0",attrs:{"label":_vm.$t('FORM_INPUT_NAMES.select_range'),"prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.formattedDates),callback:function ($$v) {_vm.formattedDates=$$v},expression:"formattedDates"}},'v-text-field',attrs,false),on))]}}],null,false,1694091490),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"range":"","no-title":"","scrollable":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dateRange)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.selectedItems,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable},on:{"click:row":_vm.handleClickItem}}),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}}),_c('InfoModalDialog',{attrs:{"dialogInfo":_vm.dialogInfo,"info":_vm.info},on:{"closeInfo":_vm.handleCloseInfo}}),_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"10","sm":"10","xs":"10"}},[(_vm.contactFormListCollection.length != 0)?_c('v-select',{attrs:{"items":Object.keys(_vm.selectedKeys).map(function (key) { return ({
                text: key,
                value: _vm.selectedKeys[key],
              }); }),"small-chips":"","deletable-chips":"true","label":_vm.$t('FORMS.fields'),"multiple":"","solo":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
return [_c('v-chip',{attrs:{"color":"bg-gray-100","close":""},on:{"click:close":function($event){return _vm.deleteChip(item, _vm.selected)}}},[_vm._v(_vm._s(item.text))])]}}],null,false,1886027403),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2","xs":"2"}},[(_vm.selectedItems.length != 0)?_c('export-excel',{staticClass:"btn",attrs:{"data":_vm.json_data,"fields":_vm.json_fields,"worksheet":"contactForms","name":_vm.exportName}},[_c('v-btn',{staticStyle:{"width":"100%!import"},attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.excel_export"))+" ")])],1):_vm._e()],1),_c('v-spacer')],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loader}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }