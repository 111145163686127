<template>
  <v-dialog v-model="dialogInfo" max-width="850px" persistent>
    <v-card>
      <v-card-title class="headline">{{ info.title }}</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="info.data">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <tr v-if="Object.keys(info.data).includes('name')">
                    <td>
                      <b>{{ $t("CONTACT_FORM.name") }}:</b>
                    </td>
                    <td>
                      {{ info.data["name"] }}
                    </td>
                  </tr>
                  <tr v-for="objKey in Object.keys(info.data)" :key="objKey">
                    <td v-if="!except_fields.includes(objKey)">
                      <b>{{ $t("CONTACT_FORM." + objKey) }}:</b>
                    </td>
                    <td v-if="!except_fields.includes(objKey)">
                      {{ info.data[objKey] }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="$emit('closeInfo')">{{
          $t("FORMS.close")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InfoModalDialog",
  props: ["dialogInfo", "info"],
  data() {
    return {
      except_fields: ["id", "custom_fields", "name"],
    };
  },
};
</script>
